﻿.btn {
    border-radius: $mbxp-border-radius;
    padding: .5rem 1.5rem;
    border: 0 none;

    &:disabled,
    &.disabled {
        cursor: not-allowed;
        background-color: #d9dcdf;
        border-color: #d9dcdf;
        color: #4F4F4F;
        font-family: EuclidFlexMedium, sans-serif;
    }
}
