@import "paygoo2_partials/_Imports";
@import "paygoo2_partials/_Variables";
@import "paygoo2_partials/_Mixins";

@import "paygoo2_partials/_Cards";
@import "paygoo2_partials/_Images";
@import "paygoo2_partials/_Basket";
@import "paygoo2_partials/_Buttons";
@import "paygoo2_partials/_Cookies";
@import "paygoo2_partials/_Dropdown";
@import "paygoo2_partials/_Forms";
@import "paygoo2_partials/_Loading";
@import "paygoo2_partials/_Tables";
@import "paygoo2_partials/_Nav";
@import "paygoo2_partials/_Custom";
@import "paygoo2_partials/_Typography";
@import "paygoo2_partials/_Countries";

html {
    height: 100vh;
}

.grecaptcha-badge {
    visibility: hidden;
}

body {
    overflow-y: scroll;
    position: relative;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 0;
    font-family: "EuclidFlex", sans-serif;
    background-color: #eaf1f0;
    color: #4d3008;
    background-image: url(../website/static/img/bg.marble.big.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
}

#main-container {
    flex: 1 0 auto;
    margin-top: 6rem;
}

/*@include media-breakpoint-up(md) {
    #main-container {
        margin-top: 0;
    }
}*/

footer {
    flex-shrink: 0;
}

.bg-dark {
    background-color: rgba(33, 38, 44, 1) !important;
}

.bg-paygoo {
    background-color: rgb(25, 204, 189) !important;
}