﻿@keyframes scroll {
    50% {
        background-size: 80%
    }

    100% {
        background-position: 125% 0;
    }
}

.l-processing {
    width: 100%;
    height: 10px;
    background: linear-gradient(to right, #25beff, #25beff);
    background-color: #ccc;
    margin: auto;
    border-radius: 1rem;
    background-size: 20%;
    background-repeat: repeat-y;
    background-position: -25% 0;
    animation: scroll 1.2s ease-in-out infinite;
}

.a-rotate {
    @include rotate(1s);
}