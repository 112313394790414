﻿.invisible-price-input {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.75rem;
    font-weight: 500;

    input {
        border: 0 none;
        font-weight: 500;
        text-align: right;
        min-width: 0;
        font-size: 1.75rem;
    }
}

.order-summary {
    .order-summary-item,
    .payment-item {
        padding-bottom: .5rem;
    }

    .freight-line,
    .discount-line,
    .total-line,
    .final-summary-line {
        border-top: 1px dashed #797979;
        display: flex;
        justify-content: space-between;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    .total-line,
    .final-summary-line {
        font-size: 1.6rem;
        padding: 1.5rem 0;
    }

    .freight-line,
    .discount-line,
    .payment-item {
        /*display: none;*/
    }

    .payment-items {
        border-top: 1px dashed #797979;
        padding: 1.5rem 0;

        .payment-item:last-child {
            padding-bottom: 0;
        }
    }
}

ol.special {
    li {
        padding: 1.5rem;
        border-top: 1px solid #E7E7E7;

        &:first-child {
            border-top: 0 none;
        }
    }
}

.error-emoji {
    font-size: 5rem;
    transform: rotate(40deg);
    display: block;
}

.success-emoji {
    font-size: 5rem;
    /*transform: rotate(40deg);*/
    display: block;
}

.h-pointer {
    cursor: pointer;
}

.shine {
    @include shine();
}

.u-transparent {
    opacity: 0.4;
}

.text-strikethrough {
    text-decoration: line-through;
}


/*.loader-2 {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    position: relative;
    animation: rotate2 1s linear infinite;

    &:before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        inset: 0px;
        border-radius: 50%;
        border: .15rem solid #fa9d1c;
        animation: prixClipFix 2s linear infinite;
    }
}
*/

.container-loader-2 {
    display: flex;
    align-content: center;
    align-items: center;
    /*justify-content: center;*/

    .loader-2 {
        width: $shadow-rolling-size;
        height: $shadow-rolling-size;
        border-radius: 50%;
        display: block;
        /*margin: 15px auto;*/
        position: relative;
        color: $shadow-rolling-color;
        left: calc(#{$shadow-rolling-size} * 10 * -1);
        box-sizing: border-box;
        animation: shadow-rolling 1.5s linear infinite;

        &.loader-2-lg {
            width: $shadow-rolling-size-lg;
            height: $shadow-rolling-size-lg;
            left: calc(#{$shadow-rolling-size-lg} * 10 * -1);
            animation: shadow-rolling-lg 1.5s linear infinite;
        }

        &.loader-2-sm {
            width: $shadow-rolling-size-sm;
            height: $shadow-rolling-size-sm;
            left: calc(#{$shadow-rolling-size-sm} * 10 * -1);
            animation: shadow-rolling-sm 1.5s linear infinite;
        }
    }
}

.h-zoom {
    &:hover {
        transform: scale(1.015);
        cursor: pointer;
        transition: transform 75ms linear;
        /*box-shadow: 0 2px 5px -3px rgba(0, 0, 0, 0.15);*/
        transform-origin: center center;
    }
}

#business-package-options {
    .custom-radio-container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-weight: normal;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 100%;
        min-height: 2.5rem;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            display: none;

            &:checked ~ .checkmark {
                /*background-color: #e3e3e3;*/
                outline: 2px solid rgb(25, 204, 189);
            }

            &:checked ~ .checkmark:after {
                display: block;
            }
        }

        &:hover input ~ .checkmark {
            background-color: #e3e3e3;
        }

        .checkmark:after {
            top: 7px;
            left: 7px;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            background: rgb(25, 204, 189);
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #fefefe;
        border-radius: 50%;
        outline: 2px solid rgba(25, 204, 189, 1);
        transition: all 0.15s ease-in-out;

        &:after {
            content: "";
            position: absolute;
            display: none;
        }
    }
}

@include media-breakpoint-up(md) {
    .jumbotron {
        margin-bottom: -6rem;
    }
}

.jumbotron-paygoo {
    background-image: url(/website/var/assets/top-slides/offset_163889.jpg);
    background-size: cover;
    height: 1000px;
    max-height: 500px;
    width: auto;
    max-width: unset;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: end;

    /*.jumbotron-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;*/
        /*margin: 15px;*/

        /*& > img {
            height: 100px;
            transform: rotate(-10deg);
        }
    }*/
}

.jumbotron-caption {
/*    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;*/
    background-color: rgba(12, 12, 12, 0.2);
    color: white;
    text-align: center;
    padding: 2rem 1rem;
    font-size: 2rem;
    font-family: EuclidFlexMedium, sans-serif;
}

.l-processing {
    background: linear-gradient(to right, #19ccbd, #19ccbd);
    background-color: #ccc;
    background-size: 20%;
    background-repeat: repeat-y;
    background-position: -25% 0;
    animation: scroll 1.2s ease-in-out infinite;
    border-radius: 0.25rem;
}

.processing-row {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background-color: rgba(0, 0, 0, .5);

    .processing-container {
        max-width: 500px;
        width: 500px;
        background-color: #fff;
        padding: 1rem;
        border-radius: .25rem;
        box-shadow: 0 2px 10px -6px rgba(0, 0, 0, .25);
        margin-top: 8rem;
    }
}

#frontpage-cards {
    .card {
        padding: 0.5rem;
        max-width: 255px;
        width: 255px;
        transition: outline 150ms ease-in-out, transform 150ms ease-in-out, box-shadow 150ms ease-in-out;
        /*outline: 2px solid transparent;*/
        /*@include slow-fade-in();*/

        @include media-breakpoint-up(md) {
            &:hover {
                /*outline: 2px solid rgb(25, 204, 189);*/
                /*transform: scale(1.075) translateY(-1rem);*/
                transform: translateY(-1rem);
                box-shadow: 0px 25px 25px -10px rgba(0, 0, 0, 0.4);
            }
        }
    }
    /*
    .card-1 {
        animation-delay: 0ms;
    }

    .card-2 {
        animation-delay: 200ms;
    }

    .card-3 {
        animation-delay: 400ms;
    }

    .card-4 {
        animation-delay: 600ms;
    }*/

    .card-body {
        height: 350px;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 0.25rem;

        .p {
            margin-bottom: 2rem;
        }

        .read-more {
            background-color: #fff;
            font-weight: bold;
            font-size: 1rem;
            border-radius: 0.5rem;
            padding: 0.5rem 1.5rem;
        }
    }
}