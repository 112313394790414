﻿
a {
    &:hover {
        text-decoration: unset;
    }
}

.text-small {
    font-size: .85rem;
}

h1, h2, h3, h4, h5, h6 {
    font-family: EuclidFlexMedium, sans-serif;
}