﻿@mixin fade-in {
    animation: fade-quick;
    animation-duration: 600ms;
    animation-fill-mode: none;
}

@mixin roll-down {
    transform-origin: top center;
    animation: roll-down 150ms ease-in-out forwards;
}

@mixin roll-up {
    transform-origin: top center;
    animation: roll-up 150ms ease-in-out forwards;
}

@mixin zoom {
    transform-origin: center center;
    animation: zoom 100ms ease-in-out forwards;
}

@mixin slide-in-right {
    transform-origin: right center;
    animation: slide-in-right 500ms ease-out forwards;
}

@mixin slow-fade-in {
    animation-name: fade-quick;
    animation-duration: 2s;
    animation-fill-mode: none;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
}

// From https://codepen.io/yangg/pen/mJBWPG 2023-09-11

@mixin saw-tooth-vertical($width: 12px, $bg: #fff) {
    background-image:
    /* Top jagged */
    linear-gradient(135deg, $bg 50%, transparent 50%), linear-gradient(225deg, $bg 50%, transparent 50%),
    /* Bottom jagged */
    linear-gradient(45deg, $bg 50%, transparent 50%), linear-gradient(-45deg, $bg 50%, transparent 50%);
    background-position:
    /* Top jagged */
    top left, top left,
    /* Bottom jagged */
    bottom left, bottom left;
    background-size: $width $width;
    background-repeat: repeat-x;
}

@mixin shine {
    background-image: linear-gradient(90deg,#0000 33%,rgba(255,255,255,0.3) 50%,#0000 66%) rebeccapurple;
    background-size: 300% 100%;
    animation: shine 2s infinite;
}

@mixin rotate($speed: 4s) {
    transform-origin: center center;
    -webkit-animation: spin $speed linear infinite;
    -moz-animation: spin $speed linear infinite;
    animation: spin $speed linear infinite;
}

@keyframes slide-in-right {
    0% {
        opacity: 0;
    }

    60% {
        opacity: 0.8;
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fade-quick {
    0% {
        opacity: 0;
    }

    60% {
        opacity: 0.8;
    }

    100% {
        opacity: 1;
    }
}

@keyframes roll-down {
    0% {
        opacity: .5;
        transform: translateY(-20px) scale(.98, .98);
    }

    100% {
        opacity: 1;
        transform: translateY(0) scale(1, 1);
    }
}

@keyframes roll-up {
    0% {
        opacity: .5;
        transform: translateY(20px) scale(.98, .98);
    }

    100% {
        opacity: 1;
        transform: translateY(0) scale(1, 1);
    }
}

@keyframes zoom {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.075);
    }
}

@keyframes shine {
    0% {
        background-position: right;
    }
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes shadow-rolling {
    0% {
        box-shadow: 0px 0 rgba($shadow-rolling-color, 0), 0px 0 rgba($shadow-rolling-color, 0), 0px 0 rgba($shadow-rolling-color, 0), 0px 0 rgba($shadow-rolling-color, 0);
    }

    12% {
        box-shadow: calc((#{$shadow-rolling-size} * 0) + (#{$shadow-rolling-size} * 10)) 0 $shadow-rolling-color, 0px 0 rgba($shadow-rolling-color, 0), 0px 0 rgba($shadow-rolling-color, 0), 0px 0 rgba($shadow-rolling-color, 0);
    }

    25% {
        box-shadow: calc((#{$shadow-rolling-size} * 1) + (#{$shadow-rolling-size} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size} * 0) + (#{$shadow-rolling-size} * 10)) 0 $shadow-rolling-color, 0px 0 rgba($shadow-rolling-color, 0), 0px 0 rgba($shadow-rolling-color, 0);
    }

    36% {
        box-shadow: calc((#{$shadow-rolling-size} * 2) + (#{$shadow-rolling-size} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size} * 1) + (#{$shadow-rolling-size} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size} * 0) + (#{$shadow-rolling-size} * 10)) 0 $shadow-rolling-color, 0px 0 rgba($shadow-rolling-color, 0);
    }

    50% {
        box-shadow: calc((#{$shadow-rolling-size} * 3) + (#{$shadow-rolling-size} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size} * 2) + (#{$shadow-rolling-size} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size} * 1) + (#{$shadow-rolling-size} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size} * 0) + (#{$shadow-rolling-size} * 10)) 0 $shadow-rolling-color;
    }

    62% {
        box-shadow: calc(#{$shadow-rolling-size} * 20) 0 rgba($shadow-rolling-color, 0), calc((#{$shadow-rolling-size} * 3) + (#{$shadow-rolling-size} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size} * 2) + (#{$shadow-rolling-size} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size} * 1) + (#{$shadow-rolling-size} * 10)) 0 $shadow-rolling-color;
    }

    75% {
        box-shadow: calc(#{$shadow-rolling-size} * 20) 0 rgba($shadow-rolling-color, 0), calc(#{$shadow-rolling-size} * 20) 0 rgba($shadow-rolling-color, 0), calc((#{$shadow-rolling-size} * 3) + (#{$shadow-rolling-size} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size} * 2) + (#{$shadow-rolling-size} * 10)) 0 $shadow-rolling-color;
    }

    87% {
        box-shadow: calc(#{$shadow-rolling-size} * 20) 0 rgba($shadow-rolling-color, 0), calc(#{$shadow-rolling-size} * 20) 0 rgba($shadow-rolling-color, 0), calc(#{$shadow-rolling-size} * 20) 0 rgba($shadow-rolling-color, 0), calc((#{$shadow-rolling-size} * 3) + (#{$shadow-rolling-size} * 10)) 0 $shadow-rolling-color;
    }

    100% {
        box-shadow: calc(#{$shadow-rolling-size} * 20) 0 rgba($shadow-rolling-color, 0), calc(#{$shadow-rolling-size} * 20) 0 rgba($shadow-rolling-color, 0), calc(#{$shadow-rolling-size} * 20) 0 rgba($shadow-rolling-color, 0), calc(#{$shadow-rolling-size} * 20) 0 rgba($shadow-rolling-color, 0);
    }
}

@keyframes shadow-rolling-lg {
    0% {
        box-shadow: 0px 0 rgba($shadow-rolling-color, 0), 0px 0 rgba($shadow-rolling-color, 0), 0px 0 rgba($shadow-rolling-color, 0), 0px 0 rgba($shadow-rolling-color, 0);
    }

    12% {
        box-shadow: calc((#{$shadow-rolling-size-lg} * 0) + (#{$shadow-rolling-size-lg} * 10)) 0 $shadow-rolling-color, 0px 0 rgba($shadow-rolling-color, 0), 0px 0 rgba($shadow-rolling-color, 0), 0px 0 rgba($shadow-rolling-color, 0);
    }

    25% {
        box-shadow: calc((#{$shadow-rolling-size-lg} * 1) + (#{$shadow-rolling-size-lg} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size-lg} * 0) + (#{$shadow-rolling-size-lg} * 10)) 0 $shadow-rolling-color, 0px 0 rgba($shadow-rolling-color, 0), 0px 0 rgba($shadow-rolling-color, 0);
    }

    36% {
        box-shadow: calc((#{$shadow-rolling-size-lg} * 2) + (#{$shadow-rolling-size-lg} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size-lg} * 1) + (#{$shadow-rolling-size-lg} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size-lg} * 0) + (#{$shadow-rolling-size-lg} * 10)) 0 $shadow-rolling-color, 0px 0 rgba($shadow-rolling-color, 0);
    }

    50% {
        box-shadow: calc((#{$shadow-rolling-size-lg} * 3) + (#{$shadow-rolling-size-lg} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size-lg} * 2) + (#{$shadow-rolling-size-lg} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size-lg} * 1) + (#{$shadow-rolling-size-lg} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size-lg} * 0) + (#{$shadow-rolling-size-lg} * 10)) 0 $shadow-rolling-color;
    }

    62% {
        box-shadow: calc(#{$shadow-rolling-size-lg} * 20) 0 rgba($shadow-rolling-color, 0), calc((#{$shadow-rolling-size-lg} * 3) + (#{$shadow-rolling-size-lg} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size-lg} * 2) + (#{$shadow-rolling-size-lg} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size-lg} * 1) + (#{$shadow-rolling-size-lg} * 10)) 0 $shadow-rolling-color;
    }

    75% {
        box-shadow: calc(#{$shadow-rolling-size-lg} * 20) 0 rgba($shadow-rolling-color, 0), calc(#{$shadow-rolling-size-lg} * 20) 0 rgba($shadow-rolling-color, 0), calc((#{$shadow-rolling-size-lg} * 3) + (#{$shadow-rolling-size-lg} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size-lg} * 2) + (#{$shadow-rolling-size-lg} * 10)) 0 $shadow-rolling-color;
    }

    87% {
        box-shadow: calc(#{$shadow-rolling-size-lg} * 20) 0 rgba($shadow-rolling-color, 0), calc(#{$shadow-rolling-size-lg} * 20) 0 rgba($shadow-rolling-color, 0), calc(#{$shadow-rolling-size-lg} * 20) 0 rgba($shadow-rolling-color, 0), calc((#{$shadow-rolling-size-lg} * 3) + (#{$shadow-rolling-size-lg} * 10)) 0 $shadow-rolling-color;
    }

    100% {
        box-shadow: calc(#{$shadow-rolling-size-lg} * 20) 0 rgba($shadow-rolling-color, 0), calc(#{$shadow-rolling-size-lg} * 20) 0 rgba($shadow-rolling-color, 0), calc(#{$shadow-rolling-size-lg} * 20) 0 rgba($shadow-rolling-color, 0), calc(#{$shadow-rolling-size-lg} * 20) 0 rgba($shadow-rolling-color, 0);
    }
}

@keyframes shadow-rolling-sm {
    0% {
        box-shadow: 0px 0 rgba($shadow-rolling-color, 0), 0px 0 rgba($shadow-rolling-color, 0), 0px 0 rgba($shadow-rolling-color, 0), 0px 0 rgba($shadow-rolling-color, 0);
    }

    12% {
        box-shadow: calc((#{$shadow-rolling-size-sm} * 0) + (#{$shadow-rolling-size-sm} * 10)) 0 $shadow-rolling-color, 0px 0 rgba($shadow-rolling-color, 0), 0px 0 rgba($shadow-rolling-color, 0), 0px 0 rgba($shadow-rolling-color, 0);
    }

    25% {
        box-shadow: calc((#{$shadow-rolling-size-sm} * 1) + (#{$shadow-rolling-size-sm} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size-sm} * 0) + (#{$shadow-rolling-size-sm} * 10)) 0 $shadow-rolling-color, 0px 0 rgba($shadow-rolling-color, 0), 0px 0 rgba($shadow-rolling-color, 0);
    }

    36% {
        box-shadow: calc((#{$shadow-rolling-size-sm} * 2) + (#{$shadow-rolling-size-sm} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size-sm} * 1) + (#{$shadow-rolling-size-sm} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size-sm} * 0) + (#{$shadow-rolling-size-sm} * 10)) 0 $shadow-rolling-color, 0px 0 rgba($shadow-rolling-color, 0);
    }

    50% {
        box-shadow: calc((#{$shadow-rolling-size-sm} * 3) + (#{$shadow-rolling-size-sm} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size-sm} * 2) + (#{$shadow-rolling-size-sm} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size-sm} * 1) + (#{$shadow-rolling-size-sm} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size-sm} * 0) + (#{$shadow-rolling-size-sm} * 10)) 0 $shadow-rolling-color;
    }

    62% {
        box-shadow: calc(#{$shadow-rolling-size-sm} * 20) 0 rgba($shadow-rolling-color, 0), calc((#{$shadow-rolling-size-sm} * 3) + (#{$shadow-rolling-size-sm} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size-sm} * 2) + (#{$shadow-rolling-size-sm} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size-sm} * 1) + (#{$shadow-rolling-size-sm} * 10)) 0 $shadow-rolling-color;
    }

    75% {
        box-shadow: calc(#{$shadow-rolling-size-sm} * 20) 0 rgba($shadow-rolling-color, 0), calc(#{$shadow-rolling-size-sm} * 20) 0 rgba($shadow-rolling-color, 0), calc((#{$shadow-rolling-size-sm} * 3) + (#{$shadow-rolling-size-sm} * 10)) 0 $shadow-rolling-color, calc((#{$shadow-rolling-size-sm} * 2) + (#{$shadow-rolling-size-sm} * 10)) 0 $shadow-rolling-color;
    }

    87% {
        box-shadow: calc(#{$shadow-rolling-size-sm} * 20) 0 rgba($shadow-rolling-color, 0), calc(#{$shadow-rolling-size-sm} * 20) 0 rgba($shadow-rolling-color, 0), calc(#{$shadow-rolling-size-sm} * 20) 0 rgba($shadow-rolling-color, 0), calc((#{$shadow-rolling-size-sm} * 3) + (#{$shadow-rolling-size-sm} * 10)) 0 $shadow-rolling-color;
    }

    100% {
        box-shadow: calc(#{$shadow-rolling-size-sm} * 20) 0 rgba($shadow-rolling-color, 0), calc(#{$shadow-rolling-size-sm} * 20) 0 rgba($shadow-rolling-color, 0), calc(#{$shadow-rolling-size-sm} * 20) 0 rgba($shadow-rolling-color, 0), calc(#{$shadow-rolling-size-sm} * 20) 0 rgba($shadow-rolling-color, 0);
    }
}