﻿@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
@import "../../lib/bootstrap462/scss/functions";

$link-color: #19ccbd !default;
$link-hover-color: darken($link-color, 15%) !default;
$brand-primary: #19ccbd;

$primary: #19ccbd;
$secondary: #edf8fa;

$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px );
$container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1140px );

@import "../../lib/bootstrap462/scss/variables";
@import "../../lib/bootstrap462/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import "morebox_partials/lightbox";

@font-face {
    font-family: 'EuclidFlex';
    src: url('../fonts/EuclidFlex.eot');
    src: url('../fonts/EuclidFlex.eot?#iefix') format('embedded-opentype'), url('../fonts/EuclidFlex.woff') format('woff'), url('../fonts/EuclidFlex.ttf') format('truetype'), url('../fonts/EuclidFlex.svg#EuclidFlex') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'EuclidFlexMedium';
    src: url('../fonts/EuclidFlexMedium.eot');
    src: url('../fonts/EuclidFlexMedium.eot?#iefix') format('embedded-opentype'), url('../fonts/EuclidFlexMedium.woff') format('woff'), url('../fonts/EuclidFlexMedium.ttf') format('truetype'), url('../fonts/EuclidFlexMedium.svg#EuclidFlexMedium') format('svg');
    font-weight: normal;
    font-style: normal;
}