﻿.cc-revoke, .cc-window, .cc-banner {
    font-family: $default-font;
    font-size: 1rem;
    border-radius: 0.25rem !important;
    margin: 0 15px 15px;
    width: auto !important;
    background-color: #eee;
    box-shadow: 0 0 .35rem rgba(0, 0, 0, .35);

    .cc-btn {
        background-color: #007bff !important;
        border: 1px solid #007bff !important;
        border-radius: 0.5rem;
        color: #fff !important;
        display: inline-block;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:hover,
        &:active,
        &:focus,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
            background-color: #0069d9 !important;
            border-color: #0062cc !important;
            text-decoration: none;
        }

        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
            outline: 0;
        }

        &:last-child {
            min-width: 140px;
        }
    }
}
