﻿.dropdown {
    .btn.btn-block {
        text-align: left;

        &.dropdown-toggle::after {
            float: right;
            margin-top: 0.55em;
        }
    }
}


.modal-content {
    border: 0 none;

    .modal-header,
    .modal-footer {
        border: 0 none;
    }
}