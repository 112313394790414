﻿

.table {
    border-collapse: separate;
    border-spacing: 0 2px;

    tbody {
        tr {
            background: none !important;

            &.tr-danger {
                td {
                    color: #fff !important;
                    background-color: $danger !important;
                }
            }
        }
    }

    &.table-striped {
        tbody {
            tr:nth-of-type(2n+1) {
                td {
                    background-color: rgba(25, 204, 189, 0.07);
                }
            }
        }
    }

    &.table-hover {
        tr:hover {
            td {
                color: $table-hover-color;
                background-color: $table-hover-bg;
            }
        }
    }

    th, td {
        &.cell-fit-content {
            width: 1%;
            white-space: nowrap;
        }
    }

    &.table-evenly {
        td, th {
            width: 25% !important;
        }
    }
}

.table {
    &.table-justified tr {
        & > td:last-child,
        & > th:last-child {
            &:not(:first-child) {
                text-align: right;
            }
        }

        & > td:first-child,
        & > th:first-child {
            &:not(:last-child) {
                text-align: left;
            }
        }
    }
}

.table-pointer {
    tbody {
        tr {
            td {
                
            }

            &:hover {
                transform: scale(1.015);
                cursor: pointer;
                transition: transform 75ms linear;
                /*box-shadow: 0 2px 5px -3px rgba(0, 0, 0, 0.15);*/
                transform-origin: center center;
            }
        }
    }
}

.terms-and-conditions-container {
    .table {
        th, td {
            &:first-child {
                width: 60%;
            }
        }
    }
}

.fees-container {
    .table {
        th, td {
            &:first-child {
                width: 75%;
            }
        }
    }
}