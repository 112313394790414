﻿
.card {
    &.card-scroll {
        .card-body {
            overflow-y: scroll;
            overflow-x: hidden;
        }
    }
}

.card {
    border: 0 none;
}

.card-reload-info {
    top: 0;
    left: 0;
    right: 0;
    margin: 15px;
    margin-top: 0;
}