﻿.custom-checkbox {
    min-height: 2.5rem;
    padding-left: 1.5rem;

    $checkbox-size: 1.5rem;

    .custom-control-input {
        width: 2rem;
        height: 2.25rem;
        cursor: pointer;

        &:checked ~ .customer-control-label:before {
            border-color: $custom-checkbox-color;
            background-color: $custom-checkbox-color;
        }
    }

    .custom-control-label {
        padding: .5rem 1rem;
        cursor: pointer;

        &:after {
            width: $checkbox-size;
            height: $checkbox-size;
            /*left: -1.5rem;*/
            top: 0.5rem;
            border-width: 2px;
        }

        &:before {
            border-radius: .25rem;
            width: $checkbox-size;
            height: $checkbox-size;
            /*left: -1.5rem;*/
            top: 0.5rem;
            border-width: 2px;
        }
    }
}

.form-control {
    border-radius: $mbxp-border-radius;
    border: 2px solid #19ccbd;

    &:disabled {
        background-color: #f2f5f8;
        cursor: not-allowed;
        border-color: #eee;
    }

    &.is-valid {
        background-color: rgba(40, 167, 69, 0.15);
    }
}

.input-group-text {
    border-radius: $mbxp-border-radius;
}

.custom-control-range {
    width: 100%;

    input[type=range] {
        -webkit-appearance: none;
        width: 100%;
        height: .75rem;
        border-radius: .75rem;
        background: #d3d3d3;
        outline: none;
        opacity: 0.7;
        -webkit-transition: .2s;
        transition: opacity .2s;
        margin: 1rem 0;

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            background: $primary;
            cursor: pointer;
            border: 0;
        }

        &::-moz-range-thumb {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            background: $primary;
            cursor: pointer;
            border: 0;
        }

        &:focus, &:active {
            border: 2px solid $primary;
        }
    }
}

.form-control-phone {
    position: relative;

    $form-offset-left: 45px;

    .form-control {
        padding-left: $form-offset-left;
    }

    .form-control-flag {
        /*background-image: url(../website/static/img/flags/4x3/fi.svg);*/
        position: absolute;
        top: 8px;
        bottom: 8px;
        left: 8px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left center;
        border-radius: 0.25rem;
        min-width: $form-offset-left;
        display: block;
    }
}

.badge-card-label {
    font-weight: 400;
    font-size: 1rem;
    padding: .4rem .75rem;

    &.badge-success {
        cursor: pointer;
    }
}