﻿.basket-container {
    position: relative;
    visibility: hidden;
    overflow: hidden;
    height: 0px;

    @media (min-width: $screen-sm-min) {
    }

    @media (min-width: $screen-md-min) {
    }

    @media (min-width: $screen-lg-min) {
        margin-left: 2rem;
        visibility: revert;
        overflow: revert;
        height: revert;
    }

    .basket-icon {
        height: 40px;
        text-align: center;
        line-height: 37px;
        color: #0b3c52;
        background: #fff;
        border-radius: 20px;
        display: inline-block;
        float: left;
        font-size: 15px;
        font-weight: bold;
        margin-top: 4px;
        position: relative;
        padding: 0 20px;
        margin-left: 0px;
        position: relative;
        border: 2px solid rgba(0, 0, 0, 0.2);

        .fa.fa-shopping-cart {
            font-size: 18px;
            margin-left: 5px;
        }

        &:hover {
            text-decoration: none;
        }

        @media (min-width: $screen-sm-min) {
            margin-left: 15px;
        }

        @media (min-width: $screen-md-min) {
            font-size: 16px;
        }
    }

    .basket-dropdown {
        position: fixed;
        left: 15px;
        right: 15px;
        border-radius: .35rem;
        background: #F7F7F7;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
        padding: 20px 34px 30px 34px;
        font-size: 16px;
        @include transition(all 200ms ease-in);
        top: 70px;
        opacity: 0;
        visibility: hidden;

        .added-items {
            margin: 0 0 20px 0;
            padding: 0;

            li {
                border-bottom: 1px dashed #797979;
                padding: 10px 0;

                &:last-child {
                    border: 0;
                }

                .price {
                    float: right;
                    margin-left: 3rem;

                    &.total {
                        float: none;
                        display: block;
                        text-align: right;
                        font-size: 25px;
                        margin-left: 0;
                    }
                }

                .icon-delete {
                    margin-right: 6px;
                    position: relative;
                    top: 6px;
                }
            }
        }

        .btn:not(:last-child) {
            margin-bottom: 10px;
        }

        @media (min-width: $screen-sm-min) {
            width: 400px;
            margin: 0;
            position: absolute;
            right: 0;
            left: auto;
        }
    }

    &:hover, &.active {
        .basket-icon {
            &:before {
                top: 6px;
            }
        }

        .basket-dropdown {
            top: 115px;
            opacity: 1;
            visibility: visible;

            @media (min-width: $screen-lg-min) {
                top: 90px;
            }
        }
    }
}

ul {
    &.navbar-nav {
        &.basket {
            flex-basis: 100%;
            flex-grow: 1;

            @include media-breakpoint-up(lg) {
                flex-basis: unset;
                flex-grow: unset;
            }
        }
    }
}

.cart-container {
    position: relative;

    i.bi {
        font-size: 2rem;
        color: white;
    }

    .items {
        position: absolute;
        top: -5px;
        right: -15px;
    }
}