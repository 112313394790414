﻿.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff #007bff #007bff;
}

.nav-pills {
    .nav-link {
        background: rgba(0, 0, 0, .05);
        margin-right: .5rem;
        font-family: EuclidFlexMedium, sans-serif;
    }
}

.navbar-nav {
    .nav-link {
        font-size: 1.1rem;
        border-radius: .5rem;
        font-family: EuclidFlexMedium, sans-serif;

        .navbar-dark & {
            color: #19ccbd;

            &:hover, &:focus {
                color: rgba(255, 255, 255, 0.75);
                background-color: rgba(255, 255, 255, .1);
            }
        }

        .navbar-expand-xl & {
            padding-right: 1rem;
            padding-left: 1rem;
            margin-right: .5rem;
        }
    }

    .btn {
        padding: 0.375rem 2rem;
        border-radius: .5rem;
        font-size: 1.15rem;
    }
}

nav.navbar {
    border-radius: 0.5rem;
    box-shadow: 0 3px 30px -10px rgba(0, 0, 0, 0.25);
    /*font-weight: 600;*/
}

.dropdown-languages {
    .dropdown-menu {
        background-color: rgb(33, 38, 44);
        min-width: unset;

        .dropdown-item {
            color: #19ccbd;
            padding: .3rem .8rem;

            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }
    }
}

@include media-breakpoint-up(xxl) {
    #main-nav {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        text-wrap: nowrap;
    }
}

#nav-tab-giftcard-new,
#nav-tab-reload-new,
#nav-tab-giftcard,
#nav-tab-reload {
    background-position: 50% -150px;
    background-repeat: no-repeat;
    background-size: cover;

    &.active {
        color: $primary;
        /*outline: 2px solid #19ccbd;*/
    }
}

#nav-tab-giftcard-new {
    background-image: url(/website/var/assets/cards/paygoo_eml_gift.jpg);
}

#nav-tab-reload-new {
    background-image: url(/website/var/assets/cards/paygoo_eml_reload.jpg);
}

#nav-tab-giftcard {
    background-image: url(/website/var/assets/cards/paygoo_gift.jpg);
}

#nav-tab-reload {
    background-image: url(/website/var/assets/cards/paygoo_reload.jpg);
}